'use client';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FlagshipProvider } from '@flagship.io/react-sdk';
import { useEnvVars } from '@web/app/contexts/EnvContext';
import { shared } from '@web/config';
import { useReporter } from '../reporter';
import { useSession } from '../api';
import { createClientVisitor } from './createClientVisitor';
import { abCampaignEvent } from './events';
import { CAMPAIGN_TYPE } from './constants';
import { useSetAnonymousVisitorId } from './useSetAnonymousVisitorId';

export const ExperimentProvider = ({
  flagshipData = { initialFlags: [], visitorId: '' },
  children,
}) => {
  const reporter = useReporter();
  const envVars = useEnvVars();
  const { initialFlags, visitorId } = flagshipData;
  const { data: session } = useSession();

  const sendFlagshipTestsRef = useRef(true);
  const cachedInitialFlags = useRef(initialFlags);
  const cachedInitialVisitorId = useRef(visitorId);
  if (visitorId) cachedInitialVisitorId.current = visitorId;
  if (initialFlags.length) cachedInitialFlags.current = initialFlags;
  const visitorData = createClientVisitor(
    cachedInitialVisitorId.current,
    !!session?.user?.id,
  );

  const customLog = {
    emergency(message, tag) {
      reporter.breadcrumb(`emergency - ${message} - ${tag}`);
    },

    alert(message, tag) {
      reporter.breadcrumb(`alert - ${message} - ${tag}`);
    },

    critical(message, tag) {
      reporter.breadcrumb(`critical - ${message} - ${tag}`);
    },

    error(message, tag) {
      reporter.breadcrumb(`error - ${message} - ${tag}`);
    },

    warning(message, tag) {
      reporter.breadcrumb(`warning - ${message} - ${tag}`);
    },

    notice(message, tag) {
      reporter.breadcrumb(`notice - ${message} - ${tag}`);
    },

    info(message, tag) {
      reporter.breadcrumb(`info - ${message} - ${tag}`);
    },

    debug(message, tag) {
      reporter.breadcrumb(`debug - ${message} - ${tag}`);
    },

    log(level, message, tag) {
      reporter.breadcrumb(`${level} - ${message} - ${tag}`);
    },
  };

  useSetAnonymousVisitorId(cachedInitialVisitorId.current, !!session?.user?.id);
  useEffect(() => {
    if (sendFlagshipTestsRef.current) {
      initialFlags
        ?.filter(item => item.campaignType === CAMPAIGN_TYPE)
        .map(item =>
          reporter.flag(
            abCampaignEvent({
              testName: item.key,
              variationId: item.variationId,
              variationName: item.variationName,
            }),
          ),
        );
      sendFlagshipTestsRef.current = false;
    }
  }, [reporter, initialFlags, sendFlagshipTestsRef]);

  return (
    <FlagshipProvider
      envId={envVars ? envVars.FLAGSHIP_ENV_ID : shared.FLAGSHIP_ENV_ID}
      apiKey={envVars ? envVars.FLAGSHIP_API_KEY : shared.FLAGSHIP_API_KEY}
      visitorData={visitorData}
      enableClientCache={true}
      fetchNow={true}
      initialFlagsData={cachedInitialFlags.current}
      logManager={customLog}
    >
      {children}
    </FlagshipProvider>
  );
};

ExperimentProvider.displayName = 'ExperimentProvider';

ExperimentProvider.propTypes = {
  flagshipData: PropTypes.shape({
    initialFlags: PropTypes.array,
  }),
  children: PropTypes.node.isRequired,
};
