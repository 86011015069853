export const variants = {
  primary: {
    active: {
      background: 'secondary.chia',
      border: 'secondary.chia',
      text: 'text.lightest',
    },
  },
  secondary: {
    active: {
      background: 'background.lightest',
      border: 'border.fur',
      text: 'text.secondary',
    },
  },
  destructive: {
    active: {
      background: 'text.lightest',
      border: 'border.fur',
      text: 'hero.cranberry',
    },
  },
  cancel: {
    active: {
      background: 'text.lightest',
      border: 'hero.cranberry',
      text: 'status.errorPrimary',
    },
  },
  dark: {
    active: {
      background: 'background.dark',
      border: 'background.dark',
      text: 'text.lightest',
    },
  },
  carrot: {
    active: {
      background: 'secondary.carrot',
      border: 'secondary.carrot',
      text: 'text.primary',
    },
  },
  chia: {
    active: {
      background: 'secondary.chia',
      border: 'secondary.chia',
      text: 'text.lightest',
    },
  },
  cranberry: {
    active: {
      background: 'hero.cranberry',
      border: 'hero.cranberry',
      text: 'text.lightest',
    },
  },
  blueberry: {
    active: {
      background: 'hero.blueberry',
      border: 'hero.blueberry',
      text: 'text.primary',
    },
  },
  egg: {
    active: {
      background: 'secondary.egg',
      border: 'secondary.egg',
      text: 'text.primary',
    },
  },
  sweetPotato: {
    active: {
      background: 'hero.sweetPotato',
      border: 'hero.sweetPotato',
      text: 'text.primary',
    },
  },
  kale: {
    active: {
      background: 'secondary.kale',
      border: 'secondary.kale',
      text: 'text.lightest',
    },
  },
  yam: {
    active: {
      background: 'secondary.yam',
      border: 'secondary.yam',
      text: 'text.lightest',
    },
  },
  spinach: {
    active: {
      background: 'hero.spinach',
      border: 'hero.spinach',
      text: 'text.primary',
    },
  },
  watermelon: {
    active: {
      background: 'hero.watermelon',
      border: 'hero.watermelon',
      text: 'text.primary',
    },
  },
  bone: {
    active: {
      background: 'neutral.bone',
      border: 'neutral.bone',
      text: 'text.secondary',
    },
  },
  fur: {
    active: {
      background: 'border.fur',
      border: 'border.fur',
      text: 'text.lightest',
    },
  },
  darkerFur: {
    active: {
      background: 'neutral.darkerFur',
      border: 'neutral.darkerFur',
      text: 'text.lightest',
    },
  },
  lightest: {
    active: {
      background: 'background.lightest',
      border: 'border.fur',
      text: 'neutral.darkerFur',
    },
  },
};
