'use client';
import Link from 'next/link';
import styled, { css } from 'styled-components';

export const NavLink = styled(Link)`
  ${props => props.theme.typography.heading3};
  color: ${props => props.theme.colors.text.primary};
  background-color: transparent;
  margin: ${props => props.theme.spacing(0, 3)};
  outline: none;
  border: none;
  padding: 14px 2px 2px 2px;
  margin-bottom: 2px !important;
  display: inline;

  &:focus,
  &:hover {
    text-decoration: none;
    padding-bottom: 0;
    border-bottom: 2px solid ${props => props.theme.colors.border.dark} !important;
  }

  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.body};

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const SmallNavLink = styled(NavLink)`
  display: ${props => props.hideOnMobile && 'none'};
  ${props => props.theme.typography.body};
  &:focus {
    outline: 0;
  }
  @media ${props => props.theme.mobileL} {
    display: inline;
  }
`;

export const Header = styled.header`
  ${props =>
    props.$sticky &&
    css`
      position: sticky;
      top: ${props => (!props.$stickyBanner && props.$hasBanner ? -40 : 0)}px;
      z-index: 100;
    `};
`;

export const StyledHeaderButton = styled.button`
  ${props => props.theme.typography.heading3};
  background: transparent;
  display: inline;
  margin: ${props => props.theme.spacing(0, 3)};
  outline: none;
  padding: 13px 2px 2px 2px;
  color: ${props => props.theme.colors.text.primary};
  border: none;
  &:focus,
  &:hover {
    text-decoration: none;
    padding-bottom: 0;
    border-bottom: 2px solid ${props => props.theme.colors.border.dark};
  }
  margin-bottom: 2px !important;
  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.body};
  }
`;
