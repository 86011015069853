export const PRODUCT_ORDER_KEY = 'productOrder';
export const OLLIE_APP_BANNER_KEY = 'OLLIE_APP_BANNER';
export const PRICE_PARITY_STARTING_AT_TEXT_KEY =
  'PRICE_PARITY_STARTING_AT_TEXT';
export const ENABLE_TURKEY_KEY = 'ENABLE_TURKEY';
export const PRICE_PARITY_PRICE_SYMBOL = 'price_parity_price_symbol';
export const SHOW_ADD_ONS_KEY = 'SHOW_ADD_ONS';
export const SHOW_HALF_FRESH = 'SHOW_HALF_FRESH';
export const OLLIE_APP_FOOTER_DOWNLOAD_BUTTONS_KEY =
  'OLLIE_APP_FOOTER_DOWNLOAD_BUTTONS';
export const CAMPAIGN_TYPE = 'ab';
export const CHECKOUT_CONFIRMATION_OLLIE_APP_BANNER_KEY =
  'CHECKOUT_CONFIRMATION_OLLIE_APP_BANNER';
export const HIDE_BAKED_PLAN_TYPE_KEY = 'HIDE_BAKED_PLAN_TYPE';
export const IS_FRESH_ONLY_KEY = 'IS_FRESH_ONLY';
export const IS_FRESH_ONLY_MEMBER_EXPERIENCE =
  'IS_FRESH_ONLY_MEMBER_EXPERIENCE';
export const HIDE_BAKED_ONLY_MEMBER_EXPERIENCE =
  'HIDE_BAKED_ONLY_MEMBER_EXPERIENCE';
export const SHOW_HALF_FRESH_MEMBER_EXPERIENCE_EDIT_FLOW =
  'SHOW_HALF_FRESH_MEMBER_EXPERIENCE_EDIT_FLOW';
export const SHOW_HALF_FRESH_MEMBER_EXPERIENCE_ADD_PUP_AND_REACTIVATION_FLOWS =
  'SHOW_HALF_FRESH_MEMBER_EXPERIENCE_ADD_PUP_AND_REACTIVATION_FLOWS';
export const SHOW_QUICK_ADD_EXTRAS_SECTION = 'SHOW_QUICK_ADD_EXTRAS_SECTION';
export const DEFAULT_CADENCE_ONBOARDING = 'DEFAULT_CADENCE_ONBOARDING';
export const flagshipData = {
  initialFlags: [
    {
      campaignType: 'toogle',
      key: 'SHOW_ADD_ONS',
      variationId: 'caqtvu2cn57g16mri14g',
      variationName: 'Show Add ons',
    },
    {
      campaignType: 'ab',
      key: 'backgroundColor',
      variationId: 'cao3sane0j9g8tkq16k0',
      variationName: 'Background Color',
    },
  ],
};
export const SHOW_PROMO_CODE_KEY = 'SHOW_PROMO_CODE_CHECKOUT';
export const SHOW_ORDER_HISTORY = 'SHOW_ORDER_HISTORY';
export const SHOW_LAMB = 'SHOW_LAMB';
export const SHOW_RECOMMENDED_PLAN = 'SHOW_RECOMMENDED_PLAN';
export const SHOW_PORTION_SECTION_WEB = 'SHOW_PORTION_SECTION_WEB';
export const SHOW_LAZYLOAD_HOMEPAGE = 'SHOW_LAZYLOAD_HOMEPAGE';
export const HIDE_VAN_LEEUWEN = 'HIDE_VAN_LEEUWEN';
export const SHORTENED_ONBOARDING_QUIZ = 'SHORTENED_ONBOARDING_QUIZ';
export const CRUNCHING_NUMBERS_CONTENT = 'CRUNCHING_NUMBERS_CONTENT';
export const HIDE_NAME_IN_EMAIL_STEP = 'HIDE_NAME_IN_EMAIL_STEP';
export const FREQUENCY_PAGE_UI_UPDATES = 'FREQUENCY_PAGE_UI_UPDATES';
export const MEAL_PREP_PAGE_UI_CRO = 'MEAL_PREP_PAGE_UI_CRO';
export const SHOW_BOX_FREQUENCY_BANNER = 'SHOW_BOX_FREQUENCY_BANNER';
export const SHOW_MOBILE_GET_STARTED = 'SHOW_MOBILE_GET_STARTED';
